body {
  background-size: cover;
  background-color: black;
}

.App {
  text-align: center;
}

.construction {
  color: white;
  flex: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 60px;
}

#clickMe {
  margin-top: 20vh;
  font-size: 20px;
  color:darkgray;
}

#coneImage {
  margin-top: 1vh;
  cursor: pointer;
}

#text {
  margin-top: 4vh;
}